import React, { FC } from 'react'
import Subtitle from 'src/components/ui/Subtitle'
import H2 from 'src/components/ui/title/H2'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface ITextInfoSectionProps {
  translationContent: {
    subtitle: string
    firstPartOfTitle: string
    secondPartOfTitle: string
    content: string
  }
  className: string
}

const FormattedHtmlContent = styled.div`
  p {
    ${tw`mb-7`}
  }
  a {
    ${tw`underline text-red`}
  }
`

const TextInfoSection: FC<ITextInfoSectionProps> = ({
  translationContent: {
    subtitle,
    firstPartOfTitle,
    secondPartOfTitle,
    content,
  },
  className,
}) => (
  <section
    className={`container flex justify-center items-center py-10 md:py-20 ${className}`}
  >
    <div className="">
      <Subtitle className="mb-2 md:mb-6 w-full">{subtitle}</Subtitle>
      <H2
        className="w-full"
        firstPart={firstPartOfTitle}
        secondPart={secondPartOfTitle}
      />
      <FormattedHtmlContent
        className="mt-12 max-w-2xl w-full text-lg text-megaDarkGray font-semibold"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  </section>
)

TextInfoSection.defaultProps = {
  className: '',
}

export default TextInfoSection
