import * as React from 'react'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import TextInfo from 'src/components/sections/TextInfo'

const IndexPage = ({ data }) => {
  const translation = data.directus.pages[0].translations[0]
  if (!translation) {
    return <h1>Missing translation</h1>
  }
  const cmsJson = JSON.parse(translation.content)
  return (
    <Layout title={cmsJson.title} metas={cmsJson.metas}>
      <TextInfo
        className="mt-16 md:mt-28"
        translationContent={cmsJson.mainSection}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      pages(filter: { slug: { _eq: "/iso" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          content
        }
      }
    }
  }
`

export default IndexPage
